body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

article {
  text-align: center;
}


.mainbox {
  height: 99vh;
  width : 98.5vw;
  background-image: radial-gradient(#9fb8ad, #1fc8db, #2cb5e8);
  text-align: center;
  margin : auto;
  margin-bottom: 1vh;
}


.second {
  text-align: center;
  height: 99vh;
  width : 98.5vw;
  background-image: radial-gradient(#9fb8ad, #55b88a, #41ee0c);
  margin : auto;
  margin-bottom : 1vh;
}

.third {
  text-align: center;
  height: 99vh;
  width : 98.5vw;
  background-color: orange;
  margin : auto;
}

.landingtext p {
  font-size : 3vw;
  margin-top : 43vh;
  color : white;
  display: inline-block;
}

i {
  margin-top : 30vh;
  border: solid white;
  border-width: 0 5px 5px 0;
  display: inline-block;
  padding: 5px;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
}

#arrowParent {
  -webkit-animation: MoveUpDown 1s infinite alternate;
          animation: MoveUpDown 1s infinite alternate;
}

@-webkit-keyframes MoveUpDown {
  0% {
    -webkit-transform: translateY(2vh);
            transform: translateY(2vh);
  }
  100% {
    -webkit-transform: translateY(7vh);
            transform: translateY(7vh);
  }
}

@keyframes MoveUpDown {
  0% {
    -webkit-transform: translateY(2vh);
            transform: translateY(2vh);
  }
  100% {
    -webkit-transform: translateY(7vh);
            transform: translateY(7vh);
  }
}

.divider {
  background-color : transparent;
  padding : 0.5vh;
}

#topProj {
  margin : auto;
  border-radius: 5px;
  width : 95vw;
  height : 28vh;
  color : white;
  background-color : transparent;
  margin-bottom : 2vh;
  border-color : white;
  border-width : 5px;
  border-style: solid;
}

#middleProj {
  margin : auto;
  border-radius: 5px;
  width : 95vw;
  height : 28vh;
  color : white;
  background-color : transparent;
  margin-bottom : 2vh;
  border-color : white;
  border-width : 5px;
  border-style: solid;
}

#bottomProj {
  margin : auto;
  border-radius: 5px;
  width : 95vw;
  height : 28vh;
  color : white;
  border-color : white;
  border-width : 5px;
  border-style: solid;
}

.projTitle { 
  font-size: 30px;
}

.txt {
  font-size: 18px;
}
/*
.container > div > div {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .text {
    pointer-events: none;
    justify-content: start !important;
    font-family: 'Kanit', sans-serif;
    line-height: 0px;
    text-transform: uppercase;
  }
  
  .number {
    font-size: 450px;
    color: #373c4c;
  }
  
  .number span {
    display: inline-block;
    position: relative;
    transform: translate3d(-35%, 0, 0);
  }
  
  .header {
    margin-left: 350px;
    font-size: 25px;
    color: white;
  }
  
  .stripe {
    height: 2px;
    width: auto;
  }
  
  .slopeBegin {
    background-color: #20232f;
    clip-path: polygon(20vw 0, 70% 0, calc(70% - 20vw) 100%, 0 100%);
  }
  
  .slopeEnd {
    clip-path: polygon(70% 0, 100% 0, calc(100% - 20vw) 100%, calc(70% - 20vw) 100%);
  }
  
  .slopeBegin,
  .slopeEnd {
    position: absolute;
    width: 140%;
    height: 100%;
    cursor: pointer;
  }
  
  .pink {
    background: linear-gradient(to right, deeppink 0%, coral 100%);
  }
  
  .teal {
    background: linear-gradient(to right, SlateBlue 0%, DeepSkyBlue 100%);
  }
  
  .tomato {
    background: linear-gradient(to right, tomato 0%, gold 100%);
  }
  
  .green {
    background : linear-gradient(to right, 	rgb(151, 255, 51) 0%, rgb(51, 204, 51) 100%)
  }

  */
.App {
  text-align: center;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

